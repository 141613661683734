import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";

import { deliveryStyles } from '../../../modules/resources/styles'
import { setInputDelivery, getDeliveries, validateTransfer, filterData, clearState } from '../../../modules/core/Delivery/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { string_general, string_delivery } from '../../../modules/resources/strings'
import Dropdown from '../../components/Dropdown'
import Table from '../../components/Table'

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

class ListDeliveries extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
  }

  componentDidMount() {
    this.props.clearState()
    this.props.getDeliveries({
      token: this.props.user.token,
      idEvent: this.props.match.params.event,
      idCustomer: this.props.idCustomerSelected,
      history: this.props.history,
      hideLoading: () => this.setState({ loading: false }),
      filterData: (listItems, idType) => this.props.filterData({
        listItems: listItems,
        idCustomer: this.props.idCustomer,
        idType: idType,
        idStatus: this.props.idStatus,
        isTransfer: this.props.isTransfer
      })
    })



    this.configMenuItems()
    window.scrollTo(0, 0);
  }

  configMenuItems() {

    const idEvent = this.props.match.params.event
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [{
        id: 1,
        label: 'Trucks',
        icon: 'tim-icons icon-delivery-fast',
        link: `/event/${idEvent}/trucks`
      },
      {
        id: 2,
        label: 'Drivers',
        icon: 'tim-icons icon-badge',
        link: `/event/${idEvent}/drivers`
      },
      {
        id: 3,
        label: 'Helpers',
        icon: 'tim-icons icon-single-02',
        link: `/event/${idEvent}/helpers`
      },
      {
        id: 4,
        label: 'Dispatch',
        icon: 'tim-icons icon-vector',
        link: `/event/${idEvent}/dispatches`
      },
      {
        id: 5,
        label: 'Deliveries',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/deliveries`

      },
      {
        id: 6,
        label: 'Transfers',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/create_transfer`

      },
    
      {
        id: 7,
        label: 'Tracking',
        icon: 'tim-icons icon-world',
        link: `/event/${idEvent}/tracking`
      },
      {
        id: 8,
        label: 'Report',
        icon: 'tim-icons icon-notes',
        link: `/event/${idEvent}/reports`
      }]
    })
    this.props.setInputUser({ prop: 'value', value: 4 })
    this.props.setInputUser({ prop: 'itemSelect', value: 5 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/events`,
          label: 'Events'
        },
        {
          link: `/events`,
          label: this.props.infoLabel
        },
        {
          link: `/event/${this.props.match.params.event}/deliveries`,
          label: 'Deliveries'
        }]
    })
  }

  renderDropDownListStatus() {
    if (!this.props.isTransfer) {
      return <div style={{ ...deliveryStyles.containerDropdownFilters, width: '100%' }}>
        <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_delivery.label_status} </label>
        <Dropdown
          selectItem={this.props.idStatus}
          placeholder={this.props.labelStatus}
          options={this.props.statusList}
          onChange={(value, description) => {
            this.props.setInputDelivery({ prop: 'idStatus', value: value })
            var list = []
            if (this.props.isTransfer) {
              list = this.props.transferList
            } else {
              list = this.props.deliveryList
            }
            this.props.filterData({ listItems: list, idCustomer: this.props.idCustomer, idType: this.props.idType, idStatus: value, isTransfer: this.props.isTransfer })
          }} />
      </div>
    }
  }

  renderTable() {
    if (this.props.isTransfer) {
      return <Table
        numberPages={this.state.numberPages}
        loading={this.state.loading}
        columns={this.props.transferColumns}
        items={this.props.itemsFilter} />
    } else {
      return <Table
        numberPages={this.state.numberPages}
        loading={this.state.loading}
        columns={this.props.deliveryColumns}
        items={this.props.itemsFilter} />
    }
  }

  render() {
    console.log("this.props.idCustomer: ", this.props.idCustomer)
    return (
      <div style={deliveryStyles.container}>

        <div style={deliveryStyles.containerFilters}>
          <div style={deliveryStyles.containerDivfilters}>
            <div style={deliveryStyles.containerDropdownFilters}>
              <label style={{ marginRight: 10, marginLeft: 3, marginTop: 3 }}>{string_general.label_dropdown_show} </label>
              <Dropdown
                selectItem={this.state.numberPages}
                placeholder={string_general.placeholder_select_rows}
                options={string_general.numberPages}
                onChange={(value, description) => {
                  this.setState({ numberPages: value })
                }} />
              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_customer} </label>
              <Dropdown
                selectItem={this.props.idCustomer}
                placeholder={this.props.labelCustomers}
                options={this.props.customerList}
                disabled={this.props.idCustomerSelected !== 0}
                onChange={(value, description) => {
                  this.props.setInputDelivery({ prop: 'idCustomer', value: value })
                  var list = []
                  if (this.props.isTransfer) {
                    list = this.props.transferList
                  } else {
                    list = this.props.deliveryList
                  }
                  this.props.filterData({ listItems: list, idCustomer: value, idType: this.props.idType, idStatus: this.props.idStatus, isTransfer: this.props.isTransfer })

                }} />

              <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_delivery.label_dropdown_type} </label>
              <Dropdown
                selectItem={this.props.idType}
                placeholder={this.props.labelTypes}
                options={this.props.typeList}
                onChange={(value, description) => {
                  this.props.setInputDelivery({ prop: 'idType', value: value })
                  this.props.validateTransfer({
                    description: description, loadFilter: (isTransfer) => {
                      var list = []
                      if (isTransfer) {
                        list = this.props.transferList
                      } else {
                        list = this.props.deliveryList
                      }
                      this.props.filterData({ listItems: list, idCustomer: this.props.idCustomer, idType: value, idStatus: this.props.idStatus, isTransfer: isTransfer })
                    }
                  })

                }} />
              {this.renderDropDownListStatus()}



            </div>
          </div>


        </div>
        {this.renderTable()}
      </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, infoLabel, idCustomerSelected } = state.user
  const { customerList, idCustomer, deliveryList, typeList, idType, statusList,
    idStatus, isTransfer, labelCustomers, labelTypes, labelStatus,
    transferList, itemsFilter, deliveryColumns, transferColumns } = state.delivery


  return {
    isAuthenticated, user, idCustomerSelected, infoLabel, customerList, idCustomer, deliveryList, typeList, idType, statusList,
    idStatus, isTransfer, labelCustomers, labelTypes, labelStatus,
    transferList, itemsFilter, deliveryColumns, transferColumns
  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  setInputDelivery,
  getDeliveries,
  validateTransfer,
  filterData,
  clearState,
  setInputUser
})(ListDeliveries)))

